import React from 'react';
import { IFormController } from '../types';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import el from 'date-fns/locale/el';

function FormDatePicker(props: IFormController) {
  const {
    label,
    name,
    required,
    formik,
    customLabel,
    filtersDesign,
    info,
    ...rest
  } = props;

  const [value, setValue] = React.useState(formik?.values[name]);

  return (
    <LocalizationProvider 
      adapterLocale={el}
      dateAdapter={AdapterDateFns}
    >
      <DatePicker
        label={label}
        format='dd/MM/yyyy'
        value={
          value
            ? value
            : formik?.getFieldProps(`${name}`).value
              ? formik?.getFieldProps(`${name}`).value
              : null
        }
        {...rest}
        onChange={(newValue: any) => {
          setValue(newValue);
          formik?.setFieldValue(`${name}`, newValue);
        }}
      />
    </LocalizationProvider>
  );
}

export default FormDatePicker;
