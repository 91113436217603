import { Box } from '@mui/material';

function FormActionButtonLayout({children}: JSX.Element | JSX.Element[] | any) {
  return (<>
    <Box
      mt={3}
      sx={{ display: 'flex', gap: '20px', justifyContent: 'flex-end' }}>
      {children}
    </Box>
  </>);
}
export default FormActionButtonLayout;