import { createTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

declare module '@mui/material/styles' {
  interface Theme {
    gradientColor?: {
      main?: string;
      background?: string;
    };
    hoverColor?: {
      main?: string;
      background?: string;
    };
  }
  interface ThemeOptions {
    gradientColor?: {
      main?: string;
      background?: string;
    }
    hoverColor?: {
      main?: string;
      background?: string;
    }
  }
}

const Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: '700',
      '@media (min-width:600px)': {
        fontSize: '2.5rem',
      },
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: '700',
      '@media (min-width:600px)': {
        fontSize: '2rem',
      },
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: '700',
      '@media (min-width:600px)': {
        fontSize: '1.3rem',
      },
    },
    h4: {
      fontSize: '1rem',
      fontWeight: '700',
      '@media (min-width:600px)': {
        fontSize: '1.1rem',
      },
    },
    h5: {
      fontSize: '0.81rem',
      fontWeight: '700',
    },
    h6: {
      fontSize: '0.81rem',
      fontWeight: '700',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.43',
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: 'transparent',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '8px 22px',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
            },
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#1B0E41',
    },
    secondary: {
      main: '#771570',
    },
    success: {
      main: '#00703c',
    },
    error: {
      main: '#ca2e2e',
    },
  },
  gradientColor: {
    main: 'linear-gradient(#1B0E41, 70%, #771570)',
    background: 'linear-gradient(135deg, #1B0E41, 70%, #771570)',
  },
  hoverColor: {
    main: '#771570',
    background: 'rgb(139 92 246 / 0.1)',
  },
});

export default Theme;
