import * as jose from 'jose';
import React, { createContext, useContext, useEffect } from 'react';
import HttpService from '../../services/HttpService';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { updateUserCoordinates } from '../../fetchers/navigator';
import { db, firebaseSignIn, firebaseSignUp, firebaseUpdateProfile } from '../../services/firebase';
import { doc, setDoc } from 'firebase/firestore';

export type User = {
  date_of_birth: string;
  description: string;
  email: string;
  first_name: string;
  is_online: boolean;
  is_available: boolean;
  last_name: string;
  specialties: { id: number; description: string; code_name: string };
  latitude: number | null;
  longitude: number | null;
  location_last_updated: string;
  has_accepted_browser_position: boolean;
  firebasepassword: string;
};

type AuthContent = {
  user: User | null;
  login: (a: string, action?: 'login' | 'register') => void;
  logout: () => void;
  isAuthenticated: boolean;
  authLoading: boolean;
  setUser: (a: User | null) => void;
}

export const AuthContext = createContext<AuthContent>({
  user: null,
  login: () => {},
  logout: () => {},
  setUser: () => {},
  isAuthenticated: false,
  authLoading: true,
});

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = React.useState<User | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const navigate = useNavigate();

  const getProfile = async () => {
    setLoading(true);
    try {
      const resp = await HttpService.get('/users/profile');
      setUser({
        ...resp.data,
        has_accepted_browser_position: true
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getProfile();
  }, []);

  const login = async (access_token: string, action: 'login' | 'register' = 'login') => {
    try {
      const decodedToken = jose.decodeJwt(access_token || '');
      const userInfo = decodedToken.userInfo as User;
      setUser({ 
        ...userInfo as User,
        has_accepted_browser_position: false
      });
  
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            setUser({ 
              ...userInfo, 
              latitude,
              longitude,
              location_last_updated: moment(new Date()).toLocaleString(),
              has_accepted_browser_position: true
            });
            updateUserCoordinates(latitude, longitude);
          },
          (error) => {
            console.error('position has error: ', error);
            setUser({
              ...userInfo,
              latitude: null,
              longitude: null,
              has_accepted_browser_position: true
            });
            updateUserCoordinates(null, null);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        setUser({
          ...userInfo,
          latitude: null,
          longitude: null,
          has_accepted_browser_position: true
        });
        updateUserCoordinates(null, null);
      }

      // FireBase Actions
      if (action === 'login') {
        await firebaseSignIn(userInfo.email, userInfo.firebasepassword);
      } else {

        const { user } = await firebaseSignUp(userInfo.email, userInfo.firebasepassword);
        
        // add displayName for user
        const displayName = userInfo.last_name
          ? `${userInfo.last_name} ${userInfo.first_name || ''}`.trim()
          : userInfo.email.split('@')[0];
        await firebaseUpdateProfile(user, displayName);
        
        // create doc collections
        await setDoc(doc(db, 'users', user.uid), {
          displayName,
          email: user.email,
          uid: user.uid
        });
        await setDoc(doc(db, 'userChats', user.uid), {});
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      navigate('/', { replace: true });
    }
  };

  const logout = async () => {
    try {
      setUser(null);
      await HttpService.post('/users/logout');
      navigate('/login', { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const isAuthenticated = !!user;

  return (
    <AuthContext.Provider
      value={{ user, login, logout, isAuthenticated, setUser, authLoading: loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.context = AuthContext;

export const useAuthContext = () => useContext(AuthContext);
