import {
  AppBar,
  CssBaseline,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Box,
  Avatar,
  Chip,
} from '@mui/material';
import React from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../components/context/Auth';
import Theme from '../../Themes/main.theme';
import { firebaseSignOut } from '../../services/firebase';
import { useConversaionsContext } from '../../components/context/Conversations';
import logo from '../../assets/logo.png';

const NavBar = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const { logout } = useAuthContext();
  const { conversations } = useConversaionsContext();
  
  const totalUnreadConversations = conversations.filter(conv => conv.unread_message > 0).length;
  
  const logOutUser = async () => {
    await firebaseSignOut();
    logout();
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{ 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: Theme.gradientColor?.main
        }}
      >
        <Toolbar>
          <Typography variant='h6' sx={{ flexGrow: 1 }}>
            <Box
              onClick={() => navigate('/')}
              sx={{
                cursor: 'pointer',
                display: 'inline-flex',
                gap: 1,
                alignItems: 'center'
              }}
            >
              <Avatar   
                sx={{ width: 24, height: 24 }}
                alt=''
                src={logo}
              />
              Pro Finder
            </Box>
          </Typography>
          <>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
              color='inherit'
            >
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id='account-menu'
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 5,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={() => navigate('/messenger')}>
                <Box 
                  display='flex' 
                  gap={1} 
                  alignItems='center'
                >
                  Μηνύματα 
                  {totalUnreadConversations > 0 &&
                    <Chip 
                      label={totalUnreadConversations} 
                      size='small' 
                      color='error'
                    />
                  }
                </Box>
              </MenuItem>
              <MenuItem onClick={() => navigate('/profile')}>Προφίλ</MenuItem>
              <MenuItem onClick={logOutUser}>Αποσύνδεση</MenuItem>
            </Menu>
          </>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
