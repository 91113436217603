import React from 'react';
import { Box, Typography, Button, MobileStepper } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { IStepperForm } from './types';
import { buildForm } from '../../utils/helpersJSX';

const MobileStepperForm = (props: IStepperForm) => {
  const { steps, formik } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const theme = useTheme();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function goToLastStep() {
    setActiveStep(maxSteps - 1);
  };

  const hasFormErrors = (): boolean => {    
    if (!formik.dirty) return true;
    return Object.keys(formik.errors).length > 0;
  };

  const SkipButton = () => <Button
    sx={{ width: 'fit-content', display: 'flex', alignSelf: 'flex-end', mb: 2 }} 
    onClick={goToLastStep}
    variant='text'
    disabled={hasFormErrors()}
    endIcon={<ExitToAppIcon />}
  >
    Skip
  </Button>;

  return (
    <>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 2
      }}>
        <Typography variant='h4'>{steps[activeStep].label}</Typography>
      </Box>
      <Box sx={{ height: 300, width: '100%', pr: 2, display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center' }}>
        {buildForm(steps[activeStep].description, formik)}
        {steps[activeStep].skipButton
          ? <Box ml='auto'>
            <SkipButton />
          </Box> 
          : null}
      </Box>
      <MobileStepper
        sx={{
          backgroundColor: 'transparent'
        }}
        variant='text'
        steps={maxSteps}
        position='static'
        activeStep={activeStep}
        nextButton={
          <Button
            size='small'
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1 || hasFormErrors()}
          >
            Επόμενο
            {theme.direction === 'rtl'
              ? (
                <KeyboardArrowLeft />
              )
              : (
                <KeyboardArrowRight />
              )}
          </Button>
        }
        backButton={
          <Button size='small' onClick={handleBack}
            disabled={activeStep === 0}>
            {theme.direction === 'rtl'
              ? (
                <KeyboardArrowRight />
              )
              : (
                <KeyboardArrowLeft />
              )}
            Προηγούμενο
          </Button>
        }
      />
    </>
  );
};

export default MobileStepperForm;