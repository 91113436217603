import { IConditionWrapper } from './conditionalwrapper.interface';

const ConditionalWrapper = (props: IConditionWrapper) => {
  const { condition, children, wrapper } = props;
  return <>
    {
      condition
        ? wrapper(children)
        : (children)
    }
  </>;
};

export default ConditionalWrapper;