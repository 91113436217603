import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import KeyIcon from '@mui/icons-material/Key';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import el from 'date-fns/locale/el';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import Theme from '../Themes/main.theme';
import AsyncButton from '../components/AsyncButton/AsyncButton';
import MapWithMarker from '../components/Map/MapWithMarker';
import Modal from '../components/Modal/Modal';
import { User, useAuthContext } from '../components/context/Auth';
import { useFirebaseContext } from '../components/context/FirebaseAuth';
import FormBuilder from '../components/forms/FormBuilder';
import FormLayout from '../components/forms/FormLayout';
import { updateUserCoordinates } from '../fetchers/navigator';
import useSpecialties from '../hooks/useSpecialties';
import HttpService from '../services/HttpService';
import { firebaseUpdateProfile } from '../services/firebase';
import { isSubmitEnabled } from '../utils/helperFunctions';
import { changePasswordFields } from '../utils/static/filterFields';
import { changePasswordInitialValues } from '../utils/static/initialValues';
import { changePasswordValidationschema } from '../utils/static/validationSchema';

const StyledIconButton = styled(IconButton)`
  transition: background-color 0.3s;
  transition: color 0.3s;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  border: 1px solid;
  &:hover {
    background-color: white;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Profile = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [dialogMode, setDialogMode] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { user, authLoading, setUser } = useAuthContext();
  const firebaseUser = useFirebaseContext();

  const { specialties } = useSpecialties();

  const [formValues, setFormValues] = React.useState<{
    date_of_birth: Date | undefined;
    description: string | undefined;
    email: string | undefined;
    first_name: string | undefined;
    last_name: string | undefined;
    specialty_id: number | undefined;
  }>({
    date_of_birth: undefined,
    description: undefined,
    email: undefined,
    first_name: undefined,
    last_name: undefined,
    specialty_id: undefined,
  });

  const changePassword = async (values: {
    email: string;
    password: string;
    new_password: string;
  }) => {
    setIsLoading(true);
    try {
      await HttpService.patch('/users/password', values);
      enqueueSnackbar('Επιτυχής Αλλαγή Κωδικού', {
        variant: 'success',
      });
      setOpen(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDialogProps = () => {
    switch (dialogMode) {
    case 'first_name':
      return {
        content: (
          <TextField
            fullWidth
            label='Όνομα'
            defaultValue={user?.first_name}
            value={formValues.first_name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFormValues({
                ...formValues,
                first_name: event.target.value,
              });
            }}
          />
        ),
        handleClose: () => {
          setFormValues({
            ...formValues,
            first_name: user?.first_name || undefined,
          });
          setOpen(false);
        },
        onAccept: async () => {
          setIsLoading(true);
          try {
            await HttpService.patch('/users/user', {
              first_name: formValues.first_name,
            });
            enqueueSnackbar('Επιτυχής Αλλαγή Στοιχείων', {
              variant: 'success',
            });
            
            const displayName = user?.last_name
              ? `${user?.last_name} ${formValues?.first_name}`.trim()
              : user?.email.split('@')[0];

            await firebaseUpdateProfile(firebaseUser, displayName || '');
            
            if (user) {
              setUser({
                ...user,
                first_name: formValues?.first_name || '',
              });
            }
            setOpen(false);
          } catch (error) {
            console.error(error);
            setIsLoading(false);
            setOpen(false);
          } finally {
            setIsLoading(false);
          }
        },
        disableAcceptButton:
            user?.first_name === formValues.first_name ||
            !formValues.first_name,
      };
    case 'last_name':
      return {
        content: (
          <TextField
            fullWidth
            label='Όνομα'
            defaultValue={user?.last_name}
            value={formValues.last_name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFormValues({
                ...formValues,
                last_name: event.target.value,
              });
            }}
          />
        ),
        handleClose: () => {
          setFormValues({
            ...formValues,
            last_name: user?.last_name || undefined,
          });
          setOpen(false);
        },
        onAccept: async () => {
          setIsLoading(true);
          try {
            await HttpService.patch('/users/user', {
              last_name: formValues.last_name,
            });
            enqueueSnackbar('Επιτυχής Αλλαγή Στοιχείων', {
              variant: 'success',
            });

            const displayName = `${formValues.last_name} ${user?.first_name || ''}`.trim();

            await firebaseUpdateProfile(firebaseUser, displayName || '');

            if (user) {
              setUser({
                ...user,
                last_name: formValues?.last_name || '',
              });
            }
            setOpen(false);
          } catch (error) {
            console.error(error);
            setIsLoading(false);
            setOpen(false);
          } finally {
            setIsLoading(false);
          }
        },
        disableAcceptButton:
            user?.last_name === formValues.last_name || !formValues.last_name,
      };
    case 'date_of_birth':
      return {
        content: (
          <LocalizationProvider
            adapterLocale={el}
            dateAdapter={AdapterDateFns}
          >
            <DatePicker
              label='Ημερομηνία Γέννησης'
              format='dd/MM/yyyy'
              {...(user?.date_of_birth && {
                defaultValue: new Date(user?.date_of_birth),
              })}
              value={formValues.date_of_birth}
              onChange={(newValue: any) => {
                setFormValues({ ...formValues, date_of_birth: newValue });
              }}
              sx={{
                width: '100%',
              }}
            />
          </LocalizationProvider>
        ),
        handleClose: () => {
          setFormValues({
            ...formValues,
            date_of_birth: user?.date_of_birth
              ? new Date(user?.date_of_birth)
              : undefined,
          });
          setOpen(false);
        },
        onAccept: async () => {
          setIsLoading(true);
          try {
            await HttpService.patch('/users/user', {
              date_of_birth: formValues.date_of_birth,
            });
            enqueueSnackbar('Επιτυχής Αλλαγή Στοιχείων', {
              variant: 'success',
            });
            if (user) {
              setUser({
                ...user,
                date_of_birth: formValues?.date_of_birth
                  ? moment(formValues?.date_of_birth).toLocaleString()
                  : '',
              });
            }
            setOpen(false);
          } catch (error) {
            console.error(error);
            setIsLoading(false);
            setOpen(false);
          } finally {
            setIsLoading(false);
          }
        },
        disableAcceptButton:
            user?.date_of_birth === formValues.date_of_birth ||
            !formValues.date_of_birth,
      };
    case 'specialty_id':
      return {
        content: (
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Ειδικότητα</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              label='Ειδικότητα'
              defaultValue={String(user?.specialties.id)}
              value={formValues.first_name}
              onChange={(event: SelectChangeEvent) => {
                setFormValues({
                  ...formValues,
                  specialty_id: Number(event.target.value),
                });
              }}
            >
              {specialties.map((spec) => {
                return <MenuItem value={spec.value}>{spec.key}</MenuItem>;
              })}
            </Select>
          </FormControl>
        ),
        handleClose: () => {
          setFormValues({
            ...formValues,
            specialty_id: user?.specialties.id || undefined,
          });
          setOpen(false);
        },
        onAccept: async () => {
          setIsLoading(true);
          try {
            await HttpService.patch('/users/user', {
              specialty_id: formValues.specialty_id,
            });
            enqueueSnackbar('Επιτυχής Αλλαγή Στοιχείων', {
              variant: 'success',
            });
            if (user && formValues.specialty_id) {
              const user_specialty = specialties.find(
                (spec) => spec.value === formValues.specialty_id
              );
              setUser({
                ...user,
                specialties: {
                  id: formValues.specialty_id,
                  description: user_specialty?.key || '',
                  code_name: user_specialty?.code_name || '',
                },
              });
            }
            setOpen(false);
          } catch (error) {
            console.error(error);
            setIsLoading(false);
            setOpen(false);
          } finally {
            setIsLoading(false);
          }
        },
        disableAcceptButton:
            user?.specialties.id === formValues.specialty_id ||
            !formValues.specialty_id,
      };
    case 'description':
      return {
        content: (
          <TextField
            multiline
            minRows={3}
            fullWidth
            label='Περιγραφή'
            defaultValue={user?.description}
            value={formValues.description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFormValues({
                ...formValues,
                description: event.target.value,
              });
            }}
          />
        ),
        handleClose: () => {
          setFormValues({
            ...formValues,
            description: user?.description || undefined,
          });
          setOpen(false);
        },
        onAccept: async () => {
          setIsLoading(true);
          try {
            await HttpService.patch('/users/user', {
              description: formValues.description,
            });
            enqueueSnackbar('Επιτυχής Αλλαγή Στοιχείων', {
              variant: 'success',
            });
            if (user) {
              setUser({
                ...user,
                description: formValues?.description || '',
              });
            }
            setOpen(false);
          } catch (error) {
            console.error(error);
            setIsLoading(false);
            setOpen(false);
          } finally {
            setIsLoading(false);
          }
        },
        disableAcceptButton:
            user?.description === formValues.description ||
            !formValues.description,
      };
    case 'password':
      return {
        content: (
          <Formik
            onSubmit={() => undefined}
            initialValues={changePasswordInitialValues(user?.email || '')}
            validationSchema={changePasswordValidationschema}
            validateOnBlur={false}
          >
            {(formik) => (
              <>
                <Form>
                  <FormLayout>
                    <FormBuilder
                      fields={changePasswordFields}
                      formik={formik}
                    />
                  </FormLayout>
                  <Box
                    display='flex'
                    justifyContent='flex-end'
                    gap={1}
                    mt={2}
                  >
                    <Button variant='outlined' onClick={() => setOpen(false)}>
                      Ακύρωση
                    </Button>
                    <AsyncButton
                      color='secondary'
                      title='Αλλαγή Κωδικού'
                      isLoading={isLoading}
                      onClick={() => changePassword(formik.values)}
                      disabled={isSubmitEnabled(
                        formik?.errors,
                        formik?.dirty
                      )}
                    />
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        ),
        handleClose: () => {},
        onAccept: () => {},
        mode: 'info' as 'info',
      };
    default:
      return {
        content: <></>,
        handleClose: () => setOpen(false),
        onAccept: () => {},
      };
    }
  };

  const changeAvailability = async (is_available?: boolean) => {
    if (is_available) localStorage.removeItem('show-available-alert');
  
    setIsLoading(true);
    try {
      await HttpService.patch('/users/user', {
        is_available,
      });
      enqueueSnackbar('Επιτυχής Αλλαγή Διαθεσιμότητας!', {
        variant: 'success',
      });
      if (user && is_available !== undefined) {
        setUser({
          ...user,
          is_available,
        });
      }
      setOpen(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setOpen(false);
    } finally {
      setIsLoading(false);
    }
  };

  const changeCoordinates = () => {
    const minutes_interval: string = moment(
      user?.location_last_updated
    ).fromNow(true);

    if (minutes_interval === 'a few seconds' || Number(minutes_interval) < 2)
      return enqueueSnackbar(
        'Πρέπει να περιμένετε τουλάχιστον 2 λεπτά για την επόμενη ανανέωση',
        { variant: 'info' }
      );

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setUser({
            ...(user as User),
            latitude,
            longitude,
            location_last_updated: moment(new Date()).toLocaleString(),
          });
          updateUserCoordinates(latitude, longitude);
        },
        (error) => {
          console.error('position has error: ', error);
          setUser({
            ...(user as User),
            latitude: null,
            longitude: null,
            location_last_updated: moment(new Date()).toLocaleString(),
          });
          updateUserCoordinates(null, null);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setUser({
        ...(user as User),
        latitude: null,
        longitude: null,
        location_last_updated: moment(new Date()).toLocaleString(),
      });
      updateUserCoordinates(null, null);
    }
  };

  if (authLoading) {
    return <>Loading..</>;
  }
  return (
    <>
      <Box display='flex' flexDirection='column'
        gap={4}>
        <div>
          <Typography variant='h3'>Βασικές Πληροφορίες</Typography>
          <Card elevation={1} sx={{ minWidth: 350, mt: 1 }}>
            <CardContent sx={{ padding: '8px !important' }}>
              <Box display='flex' mb={2}
                gap={2} alignItems='center'>
                <Avatar
                  sx={{ bgcolor: Theme.palette.secondary.main }}
                  aria-label='recipe'
                >
                  {user?.first_name?.split('')[0].toUpperCase()}
                </Avatar>
                <Typography
                  variant='body1'
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {user?.email || 'Προσθέστε Email'}
                </Typography>
              </Box>
              <Box display='flex' flexDirection='column'
                gap={2}>
                <div>
                  <Box ml={7} display='flex'
                    justifyContent='space-between'>
                    <Typography
                      variant='body1'
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      Αλλαγή Κωδικού
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setDialogMode('password');
                        setOpen(true);
                      }}
                    >
                      <KeyIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                </div>
                <Box ml={7} display='flex'
                  justifyContent='space-between'>
                  <Typography
                    variant='body1'
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    Αλλαγή Διαθεσιμότητας
                  </Typography>
                  <Switch
                    checked={user?.is_available}
                    onChange={() => changeAvailability(!user?.is_available)}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </div>
        <div>
          <Typography variant='h3'>Επιπρόσθετες Πληροφορίες</Typography>
          <Card elevation={1} sx={{ minWidth: 350, mt: 1 }}>
            <CardContent sx={{ padding: '18px !important' }}>
              <Box flexDirection='column' display='flex'
                gap={4}>
                <div>
                  <Box display='flex' justifyContent='space-between'>
                    <Box display='flex' gap={2}>
                      <Typography
                        variant='body1'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Όνομα:
                      </Typography>
                      <Typography
                        variant='body1'
                        color='text.secondary'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {user?.first_name || 'Προσθέστε Όνομα'}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() => {
                        setDialogMode('first_name');
                        setOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                </div>
                <div>
                  <Box display='flex' justifyContent='space-between'>
                    <Box display='flex' gap={2}>
                      <Typography
                        variant='body1'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Επώνυμο:
                      </Typography>
                      <Typography
                        variant='body1'
                        color='text.secondary'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {user?.last_name || 'Προσθέστε Επώνυμο'}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() => {
                        setDialogMode('last_name');
                        setOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                </div>
                <div>
                  <Box display='flex' justifyContent='space-between'>
                    <Box display='flex' gap={2}>
                      <Typography
                        variant='body1'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Ημερομηνία Γέννησης:
                      </Typography>
                      <Typography
                        variant='body1'
                        color='text.secondary'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {user?.date_of_birth
                          ? moment(user?.date_of_birth).format('DD-MM-YYYY')
                          :'Προσθέστε Ημερομηνία Γέννησης'}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() => {
                        setDialogMode('date_of_birth');
                        setOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                </div>
                <div>
                  <Box display='flex' justifyContent='space-between'>
                    <Box display='flex' gap={2}>
                      <Typography
                        variant='body1'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Ειδικότητα:
                      </Typography>
                      <Typography
                        variant='body1'
                        color='text.secondary'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {user?.specialties?.description ||
                          'Προσθέστε Ειδικότητα'}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() => {
                        setDialogMode('specialty_id');
                        setOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                </div>
                <Box display='flex' justifyContent='space-between'>
                  <Box display='flex' gap={2}>
                    <Typography
                      variant='body1'
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      Περιγραφή:
                    </Typography>
                    <Typography
                      variant='body1'
                      color='text.secondary'
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      {user?.description || 'Προσθέστε Περιγραφή'}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => {
                      setDialogMode('description');
                      setOpen(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </div>
        <div>
          <Typography variant='h3'>Η Τοποθεσία σας</Typography>
          <Card elevation={1} sx={{ minWidth: 350, mt: 1, mb: 2 }}>
            <CardContent sx={{ padding: '8px !important' }}>
              <Box sx={{ mb: 2 }}>
                <Box mb={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box display='flex' gap={2}
                      ml={2}>
                      <Typography
                        variant='body1'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Τελευταία Ενημέρωση:
                      </Typography>
                      <Typography
                        variant='body1'
                        color='text.secondary'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {moment(user?.location_last_updated).format(
                          'DD-MM-YYYY hh:mm'
                        )}
                      </Typography>
                    </Box>
                    <Tooltip placement='top' title='Ενημέρωση της Τοποθεσίας σας'>
                      <StyledIconButton onClick={changeCoordinates}>
                        <SyncIcon />
                      </StyledIconButton>
                    </Tooltip>
                  </Box>
                  {(!user?.latitude || !user?.longitude) && (
                    <Box 
                      display='flex'
                      gap={1}
                      ml={2}
                      alignItems='center'>
                      <InfoIcon color='info' />
                      <Typography variant='body2' color='secondary.main'>
                        Πρέπει να ενεργοποιήσετε την εύρεση Τοποθεσίας στον
                        Browser!
                      </Typography>
                    </Box>
                  )}
                </Box>
                <MapWithMarker
                  coordinates={{
                    latitude: user?.latitude,
                    longitude: user?.longitude,
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </div>
      </Box>
      <Modal
        fullWidth
        open={open}
        title='Αλλαγή Στοιχείων'
        {...handleDialogProps()}
        isLoading={isLoading}
      />
    </>
  );
};

export default Profile;
