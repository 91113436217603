import Input from '../Input/Input';
import { styled } from '@mui/material';

const StyledPassword = styled(Input)`
  & .MuiOutlinedInput-root {
    fieldset {
      border-color: white !important; /* Change the border color */
    }
  }

  & .MuiInputLabel-root {
    color: white !important; /* Change the label color */
  }

  & .MuiInputBase-input {
    color: white; /* Change the text color */
  }
`;

export default StyledPassword;