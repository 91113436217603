import { FieldControllerTypes, FieldTypes, IFormController } from '../../components/forms/types';
import { IBasicJSON } from '../../interfaces/generals.interface';

export const loginFields: IFormController[] = [
  {
    control: FieldControllerTypes['styled-input'],
    label: 'E-mail',
    name: 'email',
    required: true,
  },
  {
    control: FieldControllerTypes.password,
    label: 'Κωδικός',
    name: 'password',
    required: true,
    type: FieldTypes.password
  },
];

export const registerFields: IFormController[] = [
  {
    control: FieldControllerTypes.input,
    label: 'E-mail',
    name: 'email',
    required: true,
  },
  {
    control: FieldControllerTypes.input,
    label: 'Κωδικός',
    name: 'password',
    required: true,
    type: FieldTypes.password,
    helperText: 'Τουλάχιστον 8 χαρακτήρες'
  },
  {
    control: FieldControllerTypes.input,
    label: 'Επιβεβαίωση Κωδικού',
    name: 'confirmPassword',
    required: true,
    type: FieldTypes.password,
  },
];

export const registerFieldsStep2: IFormController[] = [
  {
    control: FieldControllerTypes.input,
    label: 'Όνομα',
    name: 'first_name',
  },
  {
    control: FieldControllerTypes.input,
    label: 'Επώνυμο',
    name: 'last_name',
  },
  {
    control: FieldControllerTypes.date,
    label: 'Ημερομηνία Γέννησης',
    name: 'dob',
  },
];

export const registerFieldsStep3 = (specialties: IBasicJSON[]): IFormController[] => [
  {
    control: FieldControllerTypes.select,
    label: 'Ειδικότητα',
    name: 'specialty',
    options: specialties
  },
  {
    control: FieldControllerTypes.textarea,
    label: 'Περιγραφή',
    name: 'description',
  }
];

export const changePasswordFields: IFormController[] = [
  {
    control: FieldControllerTypes.input,
    label: 'Email',
    name: 'email',
    disabled: true
  },
  {
    control: FieldControllerTypes.input,
    label: 'Κωδικός',
    name: 'password',
    type: FieldTypes.password,
  },
  {
    control: FieldControllerTypes.input,
    label: 'Καινούριος Κωδικός',
    name: 'new_password',
    type: FieldTypes.password,
    helperText: 'Τουλάχιστον 8 χαρακτήρες'
  }
];