import { useEffect, useState } from 'react';
import HttpService from '../services/HttpService';
import { useParams } from 'react-router-dom';
import { User } from '../components/context/Auth';

interface IUserProfile extends User {
  is_favourite: boolean
}

const useUserProfile = () => {
  const { id } = useParams();

  const [user, setUser] = useState<IUserProfile>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getUser = async () => {
    setIsLoading(true);
    try {
      const conversations = await HttpService.get(`/users/${id}`);
      setUser(conversations.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user,
    isLoading,
    userID: id
  };
};

export default useUserProfile;