import { Box } from '@mui/system';

const FormLayout = ({
  children,
  addMarginTop,
}: JSX.Element | JSX.Element[] | any) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          marginTop: addMarginTop
            ? '8px'
            : '25px',
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default FormLayout;