import { Box } from '@mui/material';
import FormController from './FormController';
import { IFormBuilder } from './types';

const FormBuilder = ({ fields, formik }: IFormBuilder) => {
  return (
    <>
      {fields?.map((item: any) => {
        if (Array.isArray(item)) {
          return (
            <Box display='flex' gap={3}
              key={item + 'Box'}>
              {item.map((subEl) => {
                return (
                  <div style={{ width: '100%' }} key={subEl.name}>
                    <FormController
                      {...subEl}
                      key={subEl.name}
                      formik={formik}
                    />
                  </div>
                );
              })}
            </Box>
          );
        }
        return <FormController 
          key={item.name}
          formik={formik}
          {...item} />;
      })}
    </>
  );
};

export default FormBuilder;
