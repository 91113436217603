import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import Theme from './Themes/main.theme';
import { AuthProvider } from './components/context/Auth';
import { FirebaseContextProvider } from './components/context/FirebaseAuth';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ConversaionsContextProvider } from './components/context/Conversations';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={Theme}>
          <AuthProvider>
            <FirebaseContextProvider>
              <ConversaionsContextProvider>
                <App />
              </ConversaionsContextProvider>
            </FirebaseContextProvider>
          </AuthProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
