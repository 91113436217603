import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Toolbar,
  Typography
} from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Theme from '../../Themes/main.theme';
import Modal from '../../components/Modal/Modal';
import { useFirebaseContext } from '../../components/context/FirebaseAuth';
import { db } from '../../services/firebase';
import { ISideBarMessenger } from './types';

export const drawerWidth = 280;

export default function SidebarMessenger({conversations, loadingConversations}: ISideBarMessenger) {
  const firebaseUser = useFirebaseContext();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const conversationID = searchParams.get('mid');

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [conversationIdChosen, setConversationIdChosen] = useState<string>();

  const deleteConversation = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    try {
      
      if (!firebaseUser?.uid || !conversationIdChosen) throw new Error('Κάτι πήγε Λάθος!');
      
      updateDoc(doc(db, 'userChats', firebaseUser.uid), {
        [conversationIdChosen + '.show_chat']: false,
      });
      setOpenDelete(false);
      navigate('/messenger');
    } catch (error) {
      console.error(error);
    }
  };

  const onClickChat = (conversationID: string, unread_message: number) => {
    if (!firebaseUser) return;

    if (unread_message > 0) {  
      updateDoc(doc(db, 'userChats', firebaseUser.uid), {
        [conversationID + '.incoming_unread']: 0,
      });
    }
    navigate(`/messenger?mid=${conversationID}`);
  };

  useEffect(() => {
    if (!firebaseUser) return;
    
    conversations.forEach((conversation) => {
      if (conversation.conversationID === conversationID && conversation.unread_message > 0) {
        updateDoc(doc(db, 'userChats', firebaseUser.uid), {
          [conversationID + '.incoming_unread']: 0,
        });
      }
    });
  }, [conversations, conversationID, firebaseUser]);

  const ChatsList = (
    <List sx={{ width: '100%' }}> 
      {conversations.map((conversation, index) => {
        return (
          <span key={conversation.conversationID}>
            <ListItemButton
              selected={conversation.conversationID === conversationID}
              onClick={() => onClickChat(conversation.conversationID, conversation.unread_message)}
            >
              <ListItem>
                <ListItemAvatar>
                  <Badge 
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    color='error'
                    overlap='circular' 
                    badgeContent={conversation.unread_message} 
                    invisible={conversation.unread_message === 0}
                  >
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        p: 2,
                        backgroundColor: Theme.palette.secondary.main,
                      }}
                    >
                      {!conversation?.member?.displayName
                        ? '-'
                        : conversation.member.displayName
                          .split('')[0]
                          .toUpperCase()}
                    </Avatar>
                  </Badge>
                </ListItemAvatar>
                <ListItemText
                  primary={conversation.member?.displayName || '-'}
                  secondary={conversation.latest_message_date}
                />
              </ListItem>
              <ListItemIcon onClick={(event) => {
                event.stopPropagation();
                setConversationIdChosen(conversation.conversationID);
                setOpenDelete(true);
              }}>
                <HighlightOffIcon color='secondary'/>
              </ListItemIcon>
            </ListItemButton>
            {index < conversations.length - 1 && (
              <Divider variant='middle' color={Theme.hoverColor?.main}/>
            )}
          </span>
        );
      })
      }
    </List>
  );

  return (
    <>
      <Drawer
        anchor='right'
        variant='permanent'
        sx={{
          display: { xs: 'none', md: 'block'},
          ml: 10,
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
            <Typography
              mb={3}
              variant='h3'
              display='flex'
              justifyContent='center'
            >
              Συνομιλίες
            </Typography>
            {loadingConversations
              ? <Skeleton 
                variant='rounded'
                width='100%'
                height={60} />
              : conversations.length > 0
                ? ChatsList
                : <>
                  <Typography
                    mb={3}
                    variant='body1'
                    color='GrayText'
                    display='flex'
                    justifyContent='center'
                  >
                    Δεν Έχετε Ξεκινήσει<br/>Κάποια Συνομιλία
                  </Typography>
                  <Typography
                    mb={3}
                    variant='body2'
                    color='GrayText'
                    display='flex'
                    justifyContent='center'
                  >
                    <span>
                      Επιλέξτε από την <Link to='/'>Αναζήτηση</Link> <br/>κάποιον Επαγγλεματία για<br/>Συνομιλία
                    </span>
                  </Typography>
                </>
            }
          </Box>
        </Box>
      </Drawer>
      <Modal
        content={
          <Typography>
            Είστε σίγουροος ότι θέλετε να κλείσετε την συνομιλία;
          </Typography>
        }
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        onAccept={deleteConversation}
        title='Διαγραφή Συνομιλίας'
        fullWidth
      />
    </>
  );
}
