import { Box, Toolbar, Container } from '@mui/material';
import NavBar from './layout/NavBar/NavBar';
import AppRoutes from './Routers/routes';
import ConditionalWrapper from './components/ConditionalWrapper/ConditionalWrapper';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from './components/context/Auth';
import CssBaseline from '@mui/material/CssBaseline';
import { useEffect } from 'react';
import HttpService from './services/HttpService';

const App = () => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuthContext();

  const updateOnlineStatus = async () => {    
    try {
      await HttpService.patch('/polling');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (pathname !== '/login' && pathname !== '/register' && isAuthenticated) && updateOnlineStatus();
    const intervalId = setInterval(() => {
      (pathname !== '/login' && pathname !== '/register' && isAuthenticated) && updateOnlineStatus();
    }, 1000 * 60 * 4); //1000 * 60 = every 1 minute

    return () => clearInterval(intervalId);

  }, [isAuthenticated, pathname]);

  return (
    <>
      {(pathname !== '/login' && pathname !== '/register' && isAuthenticated) && <NavBar />}
      <ConditionalWrapper 
        condition={(pathname !== '/login' && pathname !== '/register')}
        wrapper={
          (children) => <>
            <CssBaseline />
            <Container maxWidth='md'>
              <Box key='first-box-in-container'
                width='100%'
                height='100%'
                minHeight='100%'
              > 
                {children}
              </Box>
            </Container>
          </>
        }
      >
        {(pathname !== '/login' && pathname !== '/register')
          ? <Toolbar sx={{ mb: 3 }}/>
          : <></>
        }
        <AppRoutes />
      </ConditionalWrapper>
    </>
  );
};

export default App;
