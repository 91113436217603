import { TextField } from '@mui/material';
import { IFormController } from '../types';

const TextArea = (props: IFormController) => {
  const { label, name, formik, placeholder, required, ...rest } = props;
  if (!formik) return <></>;
  return (
    <>
      <TextField
        label={label}
        fullWidth
        multiline
        placeholder={placeholder}
        minRows={3}
        id={name}
        {...formik.getFieldProps(`${name}`)}
        {...rest}
      />
    </>
  );
};
export default TextArea;