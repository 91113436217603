import { User, onAuthStateChanged } from 'firebase/auth';
import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../../services/firebase';

export const FirebaseContext = createContext<User | null>(null);

export const FirebaseContextProvider = ({children}: any) => {
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setFirebaseUser(user); 
    });
  }, []);

  return (
    <FirebaseContext.Provider value={firebaseUser}>
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseContextProvider.context = FirebaseContext;

export const useFirebaseContext = () => useContext(FirebaseContext);