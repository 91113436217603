import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import customIcon from './customIcon';
import { ICoordinates } from './types';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const MapWithMultipleMarkers = (
  props: { coordinates: ICoordinates[] }
) => {

  const map_center = {
    //center of Patras
    lat: 38.246639,
    lng: 21.734573,
  };

  const mapKey = `${map_center.lat}-${map_center.lng}`;
  return (
    <MapContainer
      key={mapKey} // Use the key to force re-render when coordinates change
      center={map_center}
      zoom={13}
      style={{ height: '400px', width: '100%' }}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {
        props.coordinates.map((coord, index) => {
          return (
            (coord.latitude && coord.longitude) && 
            <Marker
              key={index}
              position={{lat: coord.latitude, lng: coord.longitude}} 
              icon={customIcon}
            >
              <Popup>
                <Typography variant='body1'>
                  <Link to={`/users/${coord.info?.id}`}>
                    {coord.info?.name}
                  </Link>
                </Typography>
                <Typography fontWeight='bold'>
                  {coord.info?.specialty}
                </Typography>
                {coord.info?.description}
              </Popup>
            </Marker>
          );
        })
      }
    </MapContainer>
  );
};

export default MapWithMultipleMarkers;
