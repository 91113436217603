import styled from '@emotion/styled';
import { Box } from '@mui/material';
import Theme from '../../Themes/main.theme';

export const ScrollbarBox = styled(Box)`
  overflow-y: auto;
  scrollbar-width: thin;  /* Firefox */
  scrollbar-color: ${Theme.palette.secondary.main} transparent;  /* Firefox */

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Theme.palette.secondary.main};  /* or any color you prefer */
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;