import { minValidationString, requiredField } from '../helperFunctions';
import * as Yup from 'yup';

export const loginValidationschema = Yup.object({
  email: Yup
    .string()
    .required(requiredField)
    .email('Μη αποδεκτή μορφή Email')
    .matches(/^\w+([.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,5}(?!\.\w))$/, 'Μη αποδεκτή μορφή Email'),
  password: Yup
    .string()
    .required(requiredField)
    .min(8, minValidationString(8)),
});

export const registerValidationschema = Yup.object({
  email: Yup
    .string()
    .required(requiredField)
    .email('Μη αποδεκτή μορφή Email')
    .matches(/^\w+([.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,5}(?!\.\w))$/, 'Μη αποδεκτή μορφή Email'),
  password: Yup
    .string()
    .required(requiredField)
    .min(8, minValidationString(8)),
  confirmPassword: Yup
    .string()
    .required(requiredField)
    .oneOf([Yup.ref('password')], 'Οι κωδικοί δεν ταιριάζουν')
});

export const changePasswordValidationschema = Yup.object({
  email: Yup
    .string()
    .required(requiredField)
    .email('Μη αποδεκτή μορφή Email')
    .matches(/^\w+([.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,5}(?!\.\w))$/, 'Μη αποδεκτή μορφή Email'),
  password: Yup
    .string()
    .required(requiredField)
    .min(8, minValidationString(8)),
  new_password: Yup
    .string()
    .required(requiredField)
    .min(8, minValidationString(8))
    .notOneOf([Yup.ref('password')], 'Οι κωδικοί δεν πρέπει να είναι ίδιοι'),
});