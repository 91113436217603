export const loginInitialValues = {
  email: '',
  password: ''
};

export const registerInitialValues = {
  email: '',
  password: '',
  confirmPassword: '',
  first_name: '',
  last_name: '',
  dob: '',
  specialty: null,
  description: ''
};

export const changePasswordInitialValues = (email: string) => {
  return {
    email,
    password: '',
    new_password: ''
  };
};