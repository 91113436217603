import HttpService from '../services/HttpService';

export const updateUserCoordinates = async (
  latitude: number | null,
  longitude: number | null,
) => {
  try {
    await HttpService.patch('/users/user', { latitude, longitude, location_last_updated: new Date() });
  } catch (error) {
    console.error(error);
  }
};