import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IModal } from './types';
import AsyncButton from '../AsyncButton/AsyncButton';

const Modal = (props: IModal) => {
  const {
    open, 
    handleClose,
    title,
    content,
    onAccept = () => {},
    fullWidth=false,
    disableAcceptButton=false,
    isLoading=false,
    mode,
    maxWidth='sm'
  } = props;

  return (
    <Dialog open={open} 
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <Typography variant='h3' sx={{ padding: 3}}>
        {title}
      </Typography>
      <DialogContent>
        {content}
      </DialogContent>
      {mode !== 'info' && (
        <DialogActions sx={{ display: 'flex', gap: 2, mr: 2}}>
          <Button onClick={handleClose} variant='outlined'>Ακύρωση</Button>
          <AsyncButton 
            isLoading={isLoading}
            title='Επιβεβαίωση'
            disabled={disableAcceptButton}
            onClick={onAccept} 
          />
        </DialogActions>
      )
      }
    </Dialog>
  );
};

export default Modal;