import { Backdrop, CircularProgress, Typography } from '@mui/material';

const AsyncBackdrop = ({ open }: { open: boolean }) => {
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color='inherit' />
        <Typography>Loading...</Typography>
      </Backdrop>
    </>
  );
};

export default AsyncBackdrop;