import { Route, Routes } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import HomePage from '../pages/HomePage';
import { Box, Typography } from '@mui/material';
import Profile from '../pages/Profile';
import RequireAuth from '../authentication/RequireAuth';
import Messenger from '../pages/Messenger';
import UserProfile from '../pages/UserProfile';
import IsAuthorized from '../authentication/IsAuthorized';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/login' element={
        <IsAuthorized>
          <LoginPage />
        </IsAuthorized>
      } />
      <Route path='/register' element={
        <IsAuthorized>
          <RegisterPage />
        </IsAuthorized>
      } />
      <Route
        path='/profile'
        element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }
      />
      <Route
        path='/users/:id'
        element={
          <RequireAuth>
            <UserProfile/>
          </RequireAuth>
        }
      />
      <Route
        path='/'
        element={
          <RequireAuth>
            <HomePage />
          </RequireAuth>
        }
      />
      <Route
        path='/messenger'
        element={
          <RequireAuth>
            <Messenger />
          </RequireAuth>
        }
      />
      <Route
        path='*'
        element={
          <Box display='flex' justifyContent='center'>
            <Typography variant='h5'>Page Not found</Typography>
          </Box>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
