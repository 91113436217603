import React from 'react';
import { IMessage } from './types';
import { Box, FormHelperText } from '@mui/material';
import './Chat.css';

const Message = ({ message }: {message: IMessage}) => {
  return (
    <>
      <Box className={
        message.own
          ? 'own'
          : 'notOwn'}
      >
        {message.message_text}
      </Box>
      <FormHelperText sx={[
        ...(message.own
          ? [{ 
            ml: 'auto',
            pr: 1
          }]
          : [{ 
            mr: 'auto',
            pl: 1
          }]
        )]
      }>
        {message.time_sent}
      </FormHelperText>
    </>
  );
};

export default Message;