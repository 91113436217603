import { IMapMarker } from './types';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import customIcon from './customIcon';

const MapWithMarker = (props: IMapMarker) => {
  const { coordinates } = props;

  const map_center =
    (coordinates?.latitude && coordinates?.longitude)
      ? {
        lat: coordinates?.latitude,
        lng: coordinates?.longitude,
      }
      : { //center of Patras
        lat: 38.246639,
        lng: 21.734573,
      };

  const mapKey = `${map_center.lat}-${map_center.lng}`;

  return (
    <MapContainer
      key={mapKey} // Use the key to force re-render when coordinates change
      center={map_center}
      zoom={14}
      style={{ height: '400px', width: '100%' }}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {(coordinates?.latitude && coordinates?.longitude) && (
        <Marker position={map_center} icon={customIcon}></Marker>
      )}
    </MapContainer>
  );
};

export default MapWithMarker;
