import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SendIcon from '@mui/icons-material/Send';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Skeleton,
  Typography
} from '@mui/material';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Theme from '../Themes/main.theme';
import AsyncBackdrop from '../components/Backdrop/AsyncBackdrop';
import ConditionalWrapper from '../components/ConditionalWrapper/ConditionalWrapper';
import MapWithMarker from '../components/Map/MapWithMarker';
import { useFirebaseContext } from '../components/context/FirebaseAuth';
import useUserProfile from '../hooks/useUserProfile';
import HttpService from '../services/HttpService';
import { startConversation } from '../utils/helperFunctions';

const UserProfile = () => {
  const { user, userID, isLoading } = useUserProfile();
  const [isFavourite, setIsFavourite] = React.useState<boolean | undefined>();
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const changeFavouriteStatus = async () => {
    try {
      await HttpService.put(`/users/favourites/${userID}`);
      setIsFavourite((prevFavourite) => !prevFavourite);
      enqueueSnackbar(
        isFavourite
          ? 'Αφαιρέθηκε από τα αγαπημένα Επιτυχώς'
          : 'Προστέθηκε στα αγαπημένα Επιτυχώς!',
        {variant: 'success'}
      );
    } catch (error) {
      console.error(error);
      navigate('');
    }
  };
  const firebaseUser = useFirebaseContext();

  const sendMessage = async (email: string | undefined) => {
    try {
      setOpenBackdrop(true);
      if (!email) throw new Error('Email was not provided!');
      await startConversation(
        email, 
        (conversationID: string) => navigate(`/messenger?mid=${conversationID}`), 
        firebaseUser
      );
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar({variant: 'error', message: error.message});
    } finally {
      setOpenBackdrop(false);
    }
  };

  React.useEffect(() => {
    user && setIsFavourite(user?.is_favourite);
  }, [user]);

  return (
    <>
      <Box display='flex' 
        flexDirection='column'
        gap={4}>
        <Box
          display='flex' 
          justifyContent='end'
          gap={2}
        >
          <ConditionalWrapper 
            condition={isLoading || isFavourite === undefined}
            wrapper={(children) => <Skeleton>{children}</Skeleton>}
          >
            <Button
              onClick={changeFavouriteStatus} 
              variant={isFavourite
                ? 'outlined'
                : 'contained'
              } 
              endIcon={<FavoriteBorderIcon />}>
              {isFavourite
                ? 'Αφαίρεση από τα Αγαπημένα'
                : 'Προσθήκη στα Αγαπημένα'
              }
            </Button>
            <Button 
              variant='contained'
              endIcon={<SendIcon />}
              disabled={!user?.is_online || !user.is_available}
              onClick={() => sendMessage(user?.email)}
            >
              Αποστολή Μηνύματος
            </Button>
          </ConditionalWrapper>
        </Box>
        <div>
          <Typography variant='h3'>Βασικές Πληροφορίες</Typography>
          <ConditionalWrapper
            condition={isLoading}
            wrapper={(children) => <Skeleton width='100%' >{children}</Skeleton>}
          >
            <Card elevation={1} sx={{ minWidth: 350, mt: 1 }}>
              <CardContent sx={{ padding: '8px !important' }}>
                <Box display='flex' mb={2}
                  gap={2} alignItems='center'>
                  <Avatar
                    sx={{ bgcolor: Theme.palette.secondary.main }}
                    aria-label='recipe'
                  >
                    {user?.first_name?.split('')[0].toUpperCase()}
                  </Avatar>
                  <Typography
                    variant='body1'
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {user?.email || 'Προσθέστε Email'}
                  </Typography>
                </Box>
                <Box display='flex' flexDirection='column'
                  gap={3} ml={7}>
                  <div>
                    <Box display='flex'
                      justifyContent='space-between'>
                      <Box display='flex' gap={2}
                        mb={1}>
                        <Typography
                          variant='body1'
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          Κατάσταση:
                        </Typography>
                        <Typography
                          variant='body1'
                          color='text.secondary'
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          {user?.is_online
                            ? 'Ενεργός'
                            : 'Ανενεργός'
                          }
                        </Typography>
                        <TripOriginIcon color={user?.is_online
                          ? 'success'
                          : 'error'}
                        />
                      </Box>
                    </Box>
                    <Divider/>
                  </div>
                  <div>
                    <Box display='flex' gap={2}
                      mb={1}>
                      <Typography
                        variant='body1'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Διαθεσιμότητα:
                      </Typography>
                      <Typography
                        variant='body1'
                        color='text.secondary'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {user?.is_available
                          ? 'Διαθέσιμος'
                          : 'Μη Διαθέσιμος'}
                      </Typography>
                    </Box>
                  </div>
                </Box>
              </CardContent>
            </Card>
          </ConditionalWrapper>
        </div>
        <div>
          <Typography variant='h3'>Επιπρόσθετες Πληροφορίες</Typography>
          <ConditionalWrapper
            condition={isLoading}
            wrapper={(children) => <Skeleton width='100%' >{children}</Skeleton>}
          >
            <Card elevation={1} sx={{ minWidth: 350, mt: 1 }}>
              <CardContent sx={{ padding: '18px !important' }}>
                <Box flexDirection='column' display='flex'
                  gap={4}>
                  <div>
                    <Box display='flex' gap={2}
                      mb={1}>
                      <Typography
                        variant='body1'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Όνομα:
                      </Typography>
                      <Typography
                        variant='body1'
                        color='text.secondary'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {user?.first_name || '-'}
                      </Typography>
                    </Box>
                    <Divider />
                  </div>
                  <div>
                    <Box display='flex' gap={2}
                      mb={1}>
                      <Typography
                        variant='body1'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Επώνυμο:
                      </Typography>
                      <Typography
                        variant='body1'
                        color='text.secondary'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {user?.last_name || '-'}
                      </Typography>
                    </Box>
                    <Divider />
                  </div>
                  <div>
                    <Box display='flex' gap={2}
                      mb={1}>
                      <Typography
                        variant='body1'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Ημερομηνία Γέννησης:
                      </Typography>
                      <Typography
                        variant='body1'
                        color='text.secondary'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {user?.date_of_birth
                          ? moment(user?.date_of_birth).format('DD-MM-YYYY') 
                          : '-'
                        }
                      </Typography>
                    </Box>
                    <Divider />
                  </div>
                  <div>
                    <Box display='flex' gap={2}
                      mb={1}>
                      <Typography
                        variant='body1'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Ειδικότητα:
                      </Typography>
                      <Typography
                        variant='body1'
                        color='text.secondary'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {user?.specialties?.description || '-'}
                      </Typography>
                    </Box>
                    <Divider />
                  </div>
                  <Box display='flex' gap={2}
                    mb={1}>
                    <Typography
                      variant='body1'
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      Περιγραφή:
                    </Typography>
                    <Typography
                      variant='body1'
                      color='text.secondary'
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      {user?.description || '-'}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </ConditionalWrapper>
        </div>
        <div>
          <Typography variant='h3'>Τοποθεσία Χρήστη</Typography>
          <ConditionalWrapper
            condition={isLoading}
            wrapper={(children) => <Skeleton width='100%' >{children}</Skeleton>}
          >
            <Card elevation={1} sx={{ minWidth: 350, mt: 1, mb: 2 }}>
              <CardContent sx={{ padding: '8px !important' }}>
                <Box display='flex' gap={2}
                  ml={2} my={2}>
                  <Typography
                    variant='body1'
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    Τελευταία Ενημέρωση:
                  </Typography>
                  <Typography
                    variant='body1'
                    color='text.secondary'
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {moment(user?.location_last_updated).format('DD-MM-YYYY hh:mm')}
                  </Typography>
                </Box>
                <MapWithMarker 
                  coordinates={{latitude: user?.latitude, longitude: user?.longitude}}
                />
              </CardContent>
            </Card>
          </ConditionalWrapper>
        </div>
      </Box>
      <AsyncBackdrop open={openBackdrop}/>
    </>
  );
};

export default UserProfile;