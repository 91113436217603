/* eslint-disable react-hooks/exhaustive-deps */
import MapIcon from '@mui/icons-material/Map';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AsyncBackdrop from '../components/Backdrop/AsyncBackdrop';
import ClosableAlert from '../components/CloseableAlert/ClosableAlert';
import MapWithMultipleMarkers from '../components/Map/MapWithMultipleMarkers';
import Modal from '../components/Modal/Modal';
import ProfessionalCard from '../components/ProfessionalCard/ProfessionalCard';
import { useAuthContext } from '../components/context/Auth';
import { useFirebaseContext } from '../components/context/FirebaseAuth';
import useSpecialties from '../hooks/useSpecialties';
import { IProfessional } from '../interfaces/professionals.interface';
import SideMenu from '../layout/SideMenu/SideMenu';
import HttpService from '../services/HttpService';
import { startConversation } from '../utils/helperFunctions';
import ResponsiveMenu from '../layout/ResponsiveMenu/ResponsiveMenu';

const HomePage = () => {
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const { user, isAuthenticated } = useAuthContext();
  const firebaseUser = useFirebaseContext();

  const [chips, setChips] = useState<string[]>([]);
  const [professionals, setProfessionals] = useState<IProfessional[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openMap, setOpenMap] = useState<boolean>(false);
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

  const { specialties, loading: specialtiesLoading } = useSpecialties();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // functions based on params, fetchers and chips
  const handleDeleteChips = (chip: string) => {
    const specialtyParams: string[] = searchParams
      .getAll('specialty')[0]
      ?.split(',');
    const indexOfSpecialty = specialtyParams.indexOf(chip);
    specialtyParams.splice(indexOfSpecialty, 1);
    setChips(specialtyParams);

    specialtyParams.length
      ? searchParams.set('specialty', specialtyParams.join(','))
      : searchParams.delete('specialty');

    navigate(`?${searchParams.toString()}`);
  };

  const fetchProfessionals = async () => {
    setIsLoading(true);
    try {
      const response = await HttpService.get(
        `/users?${searchParams.toString()}`,
        {
          params: {
            lat: user?.latitude,
            lng: user?.longitude,
          },
        }
      );
      setProfessionals(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChipsFromParams = () => {
    if (searchParams.size === 0) return setChips([]);

    const specialtiesParams = searchParams.getAll('specialty')[0]?.split(',');
    setChips(mapSpecialtyParmasToArray(specialtiesParams));
  };

  const mapSpecialtyParmasToArray = (specialtiesParams: Array<string>) => {
    return specialtiesParams
      ?.map(
        (specialtyParam) =>
          specialties.find(
            (spec) => (spec.code_name || spec.key) === specialtyParam
          )?.key || ''
      )
      .filter(Boolean);
  };

  //start conversation function
  const sendMessage = async (email: string) => {
    try {
      setOpenBackdrop(true);
      await startConversation(
        email,
        (conversationID: string) =>
          navigate(`/messenger?mid=${conversationID}`),
        firebaseUser
      );
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar({ variant: 'error', message: error.message });
    } finally {
      setOpenBackdrop(false);
    }
  };

  useEffect(() => {
    handleChipsFromParams();
    if (
      user?.latitude &&
      user.longitude &&
      user.has_accepted_browser_position
    ) {
      fetchProfessionals();
    }
  }, [searchParams, user]);

  useEffect(() => {
    handleChipsFromParams();
  }, [specialties]);

  const Map = (
    <MapWithMultipleMarkers
      coordinates={professionals.map((prof) => {
        return {
          latitude: prof.latitude,
          longitude: prof.longitude,
          info: {
            id: prof.id,
            description: prof?.description,
            specialty: prof?.specialty,
            name:
              !prof?.first_name && !prof?.last_name
                ? prof?.email
                : `${prof?.first_name} ${prof?.last_name}`,
          },
        };
      })}
    />
  );

  useEffect(() => {
    if (!user?.is_available && !localStorage.getItem('show-available-alert')) {
      localStorage.setItem('show-available-alert', 'true');
    }
  }, []);

  if (isLoading && specialtiesLoading && isAuthenticated) {
    return <>Loading...</>;
  }

  return (
    <Box display='flex'>
      <Box>
        <Box display={{ xs: 'none', sm: 'flex' }} mt={2}>
          <SideMenu specialties={specialties} />
        </Box>
        <Box display={{ sm: 'none' }}>
          <ResponsiveMenu specialties={specialties} />
        </Box>
      </Box>
      <Box width='100%'>
        <Typography 
          variant='h2' 
          textAlign={{ xs: 'center', sm: 'left' }} 
        >
          Αναζήτηση Επαγγελματιών
        </Typography>
        <Box 
          display='flex' 
          flexDirection={{xs: 'column', sm: 'row'}}
          alignItems='center' 
          gap={1} 
          mb={2} 
        >
          <Box flex='1'>
            <Typography 
              variant='body2' 
              color='text.secondary' 
            >
              Διαθέσιμοι Χρήστες Σε Ακτίνα 2 Χιλιομέτρων
            </Typography>
          </Box>
          <Button 
            variant='outlined'
            endIcon={<MapIcon color='primary'/>} 
            onClick={() => setOpenMap(true)}
          >
            <Typography 
              variant='h6' 
              color='text.secondary' 
            >
              Προβολή
            </Typography>
          </Button>
        </Box>
        <Box display='flex' minHeight='80%'>
          <Box 
            display='flex' 
            flexDirection='column' 
            width='100%'
          >
            {!user?.is_available && localStorage.getItem('show-available-alert') === 'true' && (
              <ClosableAlert
                severity='warning'
                text='Δεν είστε Ορατός! Αλλάξτε από το προφίλ σας!'
                onClose={() =>
                  localStorage.setItem('show-available-alert', 'false')
                }
              />
            )}
            {chips?.length > 0 && (
              <Box 
                display={{ xs: 'none', md: 'flex' }}
                flexWrap='wrap'
                gap={1}
                mb={2}
              >
                {chips.map((chip) => {
                  return (
                    <Chip
                      key={chip}
                      sx={{ color: 'black', backgroundColor: 'white' }}
                      label={chip}
                      variant='outlined'
                      onDelete={() => handleDeleteChips(chip)}
                    />
                  );
                })}
              </Box>
            )}

            {!user?.latitude ||
              !user.longitude ||
              !user.has_accepted_browser_position 
              ? <Box display='flex' justifyContent='center'>
                <Alert severity='warning'>
                  <AlertTitle>
                    <strong>Αδυναμία Αναζήτησης</strong>
                  </AlertTitle>
                  Παρακαλούμε επιτρέψτε στον Browser να ξέρει την τοποθεσία σας
                  ώστε να μπορέσετε να κάνετε αναζήτηση.
                  {iOS && (
                    <Typography 
                      variant='h5' 
                      fontStyle='italic' 
                      mt={1}
                    >
                      Αν δεν λάβατε ειδοποίηση πρέπει να το ενεργοποιήσετε από
                      την συσκευή σας: Settings &gt; Privacy &gt; Location
                      Services &gt; Ενεργοποίηση του Browser!
                    </Typography>
                  )}
                </Alert>
              </Box>
              : professionals.length === 0 
                ? <Box 
                  display='flex' 
                  justifyContent='center' 
                  my='auto'
                >
                  <Typography variant='h4'>
                    Δεν Υπάρχουν Ενεργοί Επαγγελματίες
                  </Typography>
                </Box>
                : (
                  <Grid
                    container
                    spacing={2}
                    height='fit-content'
                    width='100%'
                    mb={2}
                  >
                    {professionals.map((professional) => {
                      return (
                        <Grid 
                          key={professional.id} 
                          item 
                          xs={12} 
                          md={6}
                        >
                          <ProfessionalCard
                            sendMessage={(id: number, email: string) =>
                              sendMessage(email)
                            }
                            key={professional.id}
                            first_name={professional.first_name}
                            last_name={professional.last_name}
                            id={professional.id}
                            description={professional.description}
                            is_favourite={professional.is_favourite}
                            specialty={professional.specialty}
                            distance={professional.distance}
                            email={professional.email}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                )
            }
          </Box>
        </Box>
      </Box>
      <Modal
        content={Map}
        open={openMap}
        handleClose={() => setOpenMap(false)}
        title='Χάρτης Χρηστών'
        fullWidth
        mode='info'
        maxWidth='md'
      />
      <AsyncBackdrop open={openBackdrop} />
    </Box>
  );
};

export default HomePage;
