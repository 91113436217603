import { initializeApp } from 'firebase/app';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();

export const firebaseSignOut = async () => await signOut(auth);
export const firebaseSignIn = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password);
};
export const firebaseSignUp = async (email: string, password: string) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};
export const firebaseUpdateProfile = async (user: any, displayName: string) => {
  return await updateProfile(user, { displayName });
};