import { Button, Box, CircularProgress } from '@mui/material';
import { IAsyncButton } from './type';
import Theme from '../../Themes/main.theme';

const AsyncButton = (props: IAsyncButton) => {
  const {
    title,
    disabled = false,
    isLoading,
    onClick,
    endIcon,
    color = 'primary',
    gradient = false,
  } = props;

  const colorProps = {
    ...(gradient && !disabled
      ? {
        background: Theme.gradientColor?.background
      }
      : {
        color: color
      })
  };
  
  return (
    <Box position='relative'>
      <Button
        sx={colorProps}
        variant='contained'
        endIcon={endIcon}
        disabled={disabled || isLoading}
        onClick={onClick}
      >
        {title}
      </Button>
      {isLoading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  );
};

export default AsyncButton;
