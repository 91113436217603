import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../components/context/Auth';

export default function RequireAuth({ children }: any) {
  const { authLoading, user } = useAuthContext();
  
  if (authLoading && !user) {
    return <div style={{ height: '100vh' }}></div>;
  }
  
  if (!user) {
    return <Navigate
      to='/login'
      replace
    />;
  }

  return children;
}
