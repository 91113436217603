import {
  Autocomplete,
  TextField,
  Box,
  CircularProgress,
  Typography
} from '@mui/material';
import React, { useRef } from 'react';
import { DropdownOptions, IFormController } from '../types';

export interface MyMultiSelectOptions extends IFormController {
  customLabel?: boolean;
  multiple?: boolean;
  disableClearable?: boolean;
  freeSolo?: boolean;
  id?: string;
  groupByElement?: string;
  filtersDesign?: boolean;
  selectedItem?: any;
  required?: boolean;
  error?: any;
  isLoading?: boolean;
}

const AutoCompleteSelect = (props: MyMultiSelectOptions) => {
  const {
    label,
    name,
    options,
    required,
    selectedItem,
    info,
    filtersDesign,
    isLoading,
    formik,
    ...rest
  } = props;

  const LOADER_SIZE = 20;

  const errors = formik?.errors[name] as string;

  const emptyMsg = isLoading
    ? (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size={LOADER_SIZE} />
      </Box>
    )
    : (
      'Μη διαθέσιμες επιλογές'
    );

  // const reference = useRef<HTMLDivElement>(null);
  // const PopperMy = React.useCallback((props: any) => {
  //   return (
  //     <Popper
  //       {...props}
  //       style={{
  //         width: reference?.current?.clientWidth,
  //         position: 'absolute',
  //         backgroundColor: 'inherit',
  //       }}
  //       placement='bottom-start'
  //     />
  //   );
  // }, []);

  const [value, setValue] = React.useState(
    props.formik?.values?.[props.name]
      ? props.formik?.values?.[props.name]
      : null
  );

  const handleChange = (newValue: any) => {
    setValue(newValue);
    if (newValue) {
      props.formik?.setFieldValue(name, newValue);
    } else {
      props.formik?.setFieldValue(name, null);
    }
    selectedItem && selectedItem(newValue);
    props?.formik?.setTouched({ [props.name]: true });
  };

  return (
    <div 
      // ref={reference}
      style={{ position: 'relative' }}>
      <Autocomplete
        // PopperComponent={PopperMy}
        noOptionsText={emptyMsg}
        disablePortal
        {...rest}
        value={value}
        disableClearable={!!value}
        onChange={(event: any, newValue: DropdownOptions) => {
          handleChange(newValue);
        }}
        options={options || []}
        getOptionLabel={(option: any) => option.key || ''}
        fullWidth
        renderInput={(params) => (
          <TextField {...params} label={label} />
        )}
      />
      {formik?.errors[name] && formik?.touched[name]
        ? (
          <Typography color='error' variant='body1'>
            {errors}
          </Typography>
        )
        : null}
    </div>
  );
};

export default AutoCompleteSelect;
