import React from 'react';
import { FormikContextType } from 'formik';
import FormBuilder from '../components/forms/FormBuilder';

export const buildForm = (
  content: any,
  formik: FormikContextType<any>
) => {

  const formItem = content.map((item: any, index: number) => {
    if (React.isValidElement(item)) {
      return <span key={index}>
        {item}
      </span>;
    } 

    return <FormBuilder 
      key={item.name}
      formik={formik}
      fields={[item]}
    />;

  });

  return formItem;
};