import { Box, Button, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import './Chat.css';
import Message from './Message';
import { IChat } from './types';
import Theme from '../../Themes/main.theme';
import { useEffect, useRef, useState } from 'react';
import { drawerWidth } from '../../layout/Sidebar/SidebarMessenger';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { ScrollbarBox } from '../ScrollbarBox/ScrollbarBox';

const Chat = (props: IChat) => {
  const { messages, handleSubmitMessage } = props;
  
  const [searchParams] = useSearchParams();
  const conversationID = searchParams.get('mid');

  const textfieldRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const [error, setError] = useState<string>();

  const submitMessage = () => {
    setError('');
    // Handle Spam
    // Not allow above consecutive 4 messages in 2minutes
    const timedMessages = messages.slice(-4).filter(m => {
      const timeSent = moment(m.time_sent, 'DD/MM/YYYY HH:mm:ss');      
      return moment().diff(timeSent, 'minutes') < 2 && m.own;
    });

    if (timedMessages.length === 4) return setError('Περιμένετε 2 λεπτά από το τελευταίο μήνυμα μέχρι να στείλετε το επόμενο μήνυμα'); 

    handleSubmitMessage((textfieldRef.current as HTMLInputElement)?.value);
    (textfieldRef.current as HTMLInputElement).value = '';
  };

  const handleKeyPress = (event: any) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      submitMessage();
    }
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  
  useEffect(() => {
    setError('');
    (textfieldRef.current as HTMLInputElement).value = '';
  }, [conversationID]);

  return (
    <>
      <Box className='chatBox' width={{ xs: '100%', md: `calc(100% - ${drawerWidth}px)`, xl: '100%' }} >
        <Box className='chatBoxWrapper'>
          <ScrollbarBox className='chatBoxTop'>
            {
              messages.length === 0 
                ? <Typography variant='subtitle1' sx={{ display: 'flex', justifyContent: 'center', color: Theme.palette.secondary.main }}>
                  Ξεκινήστε την Συνομιλία
                </Typography> 
                : messages.map((message, index) => {
                  return (
                    <div
                      key={index}
                      ref={scrollRef} 
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Message message={message} />
                    </div>
                  );
                })
            }
          </ScrollbarBox>
          {error && (
            <Typography variant='body1' color='red'>{error}</Typography>
          )}
          <Box className='chatBoxBottom'>
            <TextField
              onKeyDown={handleKeyPress}
              inputRef={textfieldRef}
              fullWidth
              id='outlined-uncontrolled'
              placeholder='Μήνυμα'
              multiline
              rows={3}
            />
            <Button className='chatSubmitButton'
              onClick={submitMessage}
              variant='contained'
              color='primary'
              endIcon={<SendIcon />}
            >
              Αποστολή
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Chat;