import { FormikErrors } from 'formik';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { User } from 'firebase/auth';
import { db, firebaseSignIn } from '../services/firebase';
import { collection, doc, getDoc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';

export const isSubmitEnabled = (
  errors: FormikErrors<any>, touched: boolean, checkCertainErrors?: string[]
) => {
  let disabledButton;
  const errors_bool = !!Object.keys(errors).length;
  if (!touched) {
    disabledButton = true;
  } else if (checkCertainErrors) {
    disabledButton =
      checkCertainErrors?.map((check: string) => errors[check]).filter(Boolean).length > 0;
  } else if (errors_bool) {
    disabledButton = true;
  }
  return disabledButton;
};

export const eliminateOjectNotTruthyValues = (object: any) => {
  const _arr = Object.keys(object)
    .map((key) => {
      if (Array.isArray(object[key])) {
        //handle Multiselect values, returns all the { key: '', value: '' } object
        if (typeof object[key][0] === 'object' && object[key][0] !== null) {
          return {
            [key]: object[key]
          };
        }
        if (object[key].length > 0) {
          return {
            [key]: object[key].reduce((x: any, y: any) => `${x},${y}`),
          };
        }
        return {};
      } else if (
        object[key] instanceof Date
      ) {
        return {
          [key]: moment(object[key]).toLocaleString()
        };
        
      } else if (
        typeof object[key] === 'object' &&
        object[key] !== null
      ) {        
        return {
          [key]: object[key].value,
        };
      }
      else if (
        object[key] !== null &&
        object[key] !== undefined &&
        object[key] !== '' &&
        object[key] !== 'all'
      ) {
        return {
          [key]: object[key],
        };
      } else return {};
    })
    .filter(Boolean); // filter: eliminate not truthy values
  return Object.assign({}, ..._arr);
};

export const maxValidationString = (maxChar: number, keywordHelperText: string | undefined = '') => (`Παρακαλώ συμπληρώστε μέχρι ${maxChar} χαρακτήρες ${keywordHelperText}\n`);

export const minValidationString = (minChar: number) => (`Παρακαλώ συμπληρώστε τουλάχιστον ${minChar} χαρακτήρες`);

export const maxValidationNumber = (maxChar: number) => (`Ο αριθμός δεν μπορεί να είναι μεγαλύτερος από ${maxChar}`);

export const minValidationNumber = (minChar: number) => (`Ο αριθμός δεν μπορεί να είναι μικρότερος από ${minChar}`);

export const postalCodeErrorMessage = () => ('Παρακαλώ συμπληρώστε αριθμό με ακριβώς 5 ψηφία');

export const requiredField = 'Υποχρεωτικό πεδίο, παρακαλώ συμπληρώστε.';

export const useQuery = (queryParam: string) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

export const findParams = (param: any, searchParams: any) => {
  const specialtyParam = searchParams.get('specialty');
  const hasSpecialty = !!(specialtyParam && specialtyParam.includes(param));
  return hasSpecialty;
};

export function containsOnlySpaces(input: string): boolean {
  return /^ *$/.test(input);
}

export const getRemainingSubstring = (wholeString: string, substring: string) => {
  if (!wholeString.includes(substring)) throw new Error('The second string is not a substring of the first string.');
  return wholeString.replace(substring, '');
};

export const buildCombinedId = (currentUserUid: string, userUid: string) => {
  return currentUserUid > userUid
    ? currentUserUid + userUid
    : userUid + currentUserUid;
};

export const checkFirebaseUser = async (
  firebaseUser: User | null, 
  user: { email: string, firebasepassword: string }
) => {
  if (firebaseUser) return firebaseUser;
  const { user: fbUser } = await firebaseSignIn(user?.email || '', user?.firebasepassword || '');
  return fbUser;
};

export const startConversation = async (
  email: string, 
  onSuccess = (conversationID: string) => {}, 
  firebaseUser: User | null
) => {
  const fbUser = await checkFirebaseUser(firebaseUser, { email: '', firebasepassword: '' });

  try {
    if (!fbUser) throw new Error('Κάτι πήγε στραβά! Προσπαθήστε να κάνετε Σύνδεση πάλι αλλιώς επικοινωνήστε με κάποιον υπεύθυνο!');
  
    const queryFirebase = query(collection(db, 'users'), where('email', '==', email));
    const querySnapshot = await getDocs(queryFirebase);
    const targettedUser = {
      uid: '',
      displayName: ''
    };
    querySnapshot.forEach((doc) => {
      targettedUser.uid = doc.id;
      targettedUser.displayName = doc.data().displayName;
    });

    const conversationID = buildCombinedId(fbUser.uid, targettedUser.uid);
    
    // check if chat exists
    const res = await getDoc(doc(db, 'chats', conversationID));
    
    if (!res.exists()) {
    // initialise chat between users
      await setDoc(doc(db, 'chats', conversationID), { messages: []});

      await updateDoc(doc(db, 'userChats', fbUser?.uid), {
        [conversationID + '.userInfo']: {
          uid: targettedUser.uid,
          displayName: targettedUser.displayName,
        },
        [conversationID + '.date']: serverTimestamp(),
        [conversationID + '.show_chat']: true,
      });

    }
    await updateDoc(doc(db, 'userChats', fbUser?.uid), {
      [conversationID + '.show_chat']: true,
    });

    onSuccess(conversationID);
  } catch (error) {
    console.error(error);
    throw error;
  }
};