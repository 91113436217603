import { Box, Typography, Grid, Fab } from '@mui/material';
import { Form, Formik, FormikContextType } from 'formik';
import { useState, useRef } from 'react';
import AsyncButton from '../components/AsyncButton/AsyncButton';
import { Link } from 'react-router-dom';
import {
  registerFields,
  registerFieldsStep2,
  registerFieldsStep3,
} from '../utils/static/filterFields';
import { registerInitialValues } from '../utils/static/initialValues';
import { registerValidationschema } from '../utils/static/validationSchema';
import backGroundImage from '../assets/pexels-brayden-law-2096700.jpg';
import { HowToReg, KeyboardArrowDown } from '@mui/icons-material';
import icon from '../assets/register.svg';
import MobileStepperForm from '../components/MobileStepper/MobileStepperForm';
import FormLayout from '../components/forms/FormLayout';
import { IStep } from '../components/MobileStepper/types';
import HttpService from '../services/HttpService';
import { eliminateOjectNotTruthyValues } from '../utils/helperFunctions';
import useSpecialties from '../hooks/useSpecialties';
import { useAuthContext } from '../components/context/Auth';

const RegisterPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { login } = useAuthContext();

  const { specialties } = useSpecialties();

  const formRef = useRef<FormikContextType<any>>(null);

  const onRegister = async () => {
    setIsLoading(true);
    try {
      const response = await HttpService.post('/users/register', eliminateOjectNotTruthyValues(formRef.current?.values));
      login(response.data.access_token, 'register');
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const steps: IStep[] = [
    { 
      label: 'Δημιουργία Λογαριασμού',
      description: registerFields,
    },
    {
      label: 'Δημιουργία Λογαριασμού',
      description: registerFieldsStep2,
      skipButton: true,
    },
    {
      label: 'Δημιουργία Λογαριασμού',
      description: registerFieldsStep3(specialties),
      skipButton: true,
    },
    {
      label: 'Δημιουργία Λογαριασμού',
      description: [
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          gap={4}
        >
          <div style={{ width: '150px' }}>
            <img src={icon} alt='mySvgImage' />
          </div>
          <AsyncButton
            gradient
            endIcon={<HowToReg />}
            title='Ολοκλήρωση Εγγραφής'
            isLoading={isLoading}
            onClick={onRegister}
          />
        </Box>
      ],
    },
  ];

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}
          md={6}>
          <Fab
            onClick={scrollToBottom}
            variant='extended'
            sx={{
              display: { md: 'none' },
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <KeyboardArrowDown sx={{ mr: 1 }} />
            Εγγραφη
          </Fab>
          <Box
            sx={{
              backgroundImage: `url(${backGroundImage})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          ></Box>
        </Grid>
        <Grid
          item
          xs
          display='flex'
          flexDirection='column'
          alignItems={{ sm: 'center' }}
          justifyContent='center'
        >
          <Box 
            minWidth='fit-content' 
            width='50%'
            mb={{xs: 2, md: 0}}
          >
            <div className='glass-effect'>
              <Formik
                innerRef={formRef}
                onSubmit={() => undefined}
                initialValues={registerInitialValues}
                validationSchema={registerValidationschema}
                validateOnBlur={false}
              >
                {(formik) => (
                  <Form>
                    <FormLayout>
                      <MobileStepperForm formik={formik} steps={steps} />
                    </FormLayout>
                  </Form>
                )}
              </Formik>
              <Box
                sx={{
                  marginTop: '16px',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <Link
                  to='/login'
                  style={{
                    textDecorationColor: 'black',
                    color: 'black',
                  }}
                >
                  <Typography variant='body1'>Έχετε λογαριασμό;</Typography>
                </Link>
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterPage;
