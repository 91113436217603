import { FormikContextType } from 'formik';

export enum FieldControllerTypes {
  input = 'input',
  'styled-input' = 'styled-input',
  password = 'password',
  telInput = 'telInput',
  number = 'number',
  select = 'select',
  checkbox = 'checkbox',
  autocomplete = 'autocomplete',
  tags = 'tags',
  textarea = 'textarea',
  textfield = 'textfield',
  radio = 'radio',
  date = 'date',
  time = 'time',
  file = 'file',
  selectWithCheckboxes = 'selectWithCheckboxes',
  chips = 'chips',
  checkboxTerms = 'checkboxTerms',
  autoselect = 'autoselect',
  multiselectall = 'multiselectall',
  multiselecttree = 'multiselecttree'
}

export enum FieldTypes {
  select = 'select',
  number = 'number',
  text = 'text',
  email = 'email',
  checkbox = 'checkbox',
  date = 'date',
  time = 'time',
  file = 'file',
  search = 'text',
  radio = 'radio',
  autocomplete = 'autocomplete',
  textfield = 'textfield',
  password = 'password'
}

export interface DropdownOptions {
  key: string;
  value: boolean | string | number;
  parent?: string;
}

export interface IFormController<FormikValues = any> {
  hidden?: boolean;
  key?: string | undefined;
  control?: FieldControllerTypes;
  type?: FieldTypes;
  label?: string | undefined;
  name: string;
  disabled?: boolean;
  options?: DropdownOptions[];
  required?: boolean;
  formik?: FormikContextType<FormikValues>;
  title?: string | undefined;
  labelSize?: boolean;
  placeholder?: string;
  customLabel?: boolean;
  description?: string | undefined;
  info?: boolean;
  groupByElement?: string;
  tooltip?: string;
  multiple?: boolean;
  accepts?: string[] | [];
  filtersDesign?: boolean;
  errors?: boolean;
  endAdornment?: JSX.Element | JSX.Element[] | string;
  startAdornment?: JSX.Element | JSX.Element[] | string;
  link?: true;
  onFileSelect?: (file: any) => void;
  selectedItem?: (selectedValues: any) => void;
  positives_only?: boolean;
  nofiles?: number; //number of files when multiple = true
  isLoading?: boolean;
  helperText?: string
}

export interface IFormBuilder {
  fields: IFormController[] | IFormController[][],
  formik: FormikContextType<any>
}